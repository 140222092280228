import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `excavation`,
          `tiling`,
          `drainage tiling`,
          `agricultural drainage tile`,
          `agricultural tiling`,
          `excavation contractor`,
          `residential excavation`,
          `commercial excavation`,
          `Ostrom Contracting`,
          `Mac Ostrom`,
        ]}
        title="About"
      />
      <h1>About Page</h1>
    </Layout>
  );
}

export default AboutPage;
